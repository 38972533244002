<template>
    <loading-animation v-if="DataLoading"></loading-animation>
    <div v-else class="m-3">
        <div class="card bs-4 p-0 pb-3">
            <div class="fl-te-c mb-3 bb-1 p-3">
                <h5>Review Details # {{ details.id }}</h5>
                <div class="btn-group">
                    <btn size="sm" @click="$router.push('/sales/review/list/')" text="Back"/>
                </div>
            </div>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-1 label">Review ID</div>
                <div class="col-2 p-1 b-1 text-muted">{{ details.id }}</div>
                <div class="col-1"></div>
                <div class="col-1 label">Title</div>
                <div class="col-2 p-1 b-1 text-muted">{{ details.heading }}</div>
                <div class="col-1"></div>
                <div class="col-1 label">Rating</div>
                <div class="col-2 p-1 b-1 text-muted">{{ details.rating }}</div>
                <div class="col-1"></div>
            </div>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-1 label">Product</div>
                <div class="col-2 p-1 b-1 text-muted">{{ details.product }}</div>
                <div class="col-1"></div>
                <div class="col-1 label">Date</div>
                <div class="col-2 p-1 b-1 text-muted">{{ details.date }}</div>
                <div class="col-1"></div>
                <div class="col-1 label">Status</div>
                <div class="col-2 p-1 b-1 text-muted">{{ details.is_active }}</div>
                <div class="col-1"></div>
            </div>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-1 label">Comment</div>
                <div class="form-textarea round-1 col-6 p-2 b-1 text-muted">
                    {{ details.description }}
                </div>
                <div class="col-1"></div>
            </div>
        </div>
    </div>
</template>

<script>
import urls from '../../../../../data/urls';
import axios from 'secure-axios';

export default {
    name : 'DetailsComponent',
    data () {
        return {
            DataLoading : false,
            id          : this.$route.params.id,
            details     : '',
            loading     : false
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods : {
        async loadDetails () {
            const that = this;
            that.DataLoading = true;
            const response = await axios.form(urls.salesAdmin.customers.details, { review_id : that.id });
            const json = response.data;
            that.details = json.data;
            that.DataLoading = false;
        }
    }
};
</script>

<style scoped>
.b-1 {
    border-radius: 4px;
}

.label {
    font-weight: 500;
}
</style>
